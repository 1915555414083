import React from 'react';
import Logo from '../assets/images/Logo.png'

const Footer = () => {
    return (
        <div className='bg-dark py-2 mt-8 text-center text-white'>
            <div className='flex items-center justify-center gap-x-2'>
                <p className='uppercase font-bold text-lg'>benenfits now</p>
                <img src={Logo} alt="logo" width={30} />
            </div>
            <p className='font-light text-sm'>Copyright © 2024. All rights reserved</p>
        </div>
    );
};

export default Footer;

import React from 'react';
import { BsChevronDoubleUp } from "react-icons/bs";
import useSmoothScroll from '../hooks/useSmoothScroll';
import Logo from '../assets/images/Logo.png'

const Navbar = () => {
    const { sticky, scrollToTop } = useSmoothScroll()

    return (
        <nav>
            <div className='flex items-center gap-x-2'>
                <p className='text-white uppercase font-bold text-2xl'>benefits now</p>
                <img src={Logo} alt="logo" width={30} />
            </div>
            {sticky && (
                <button className="fixed bottom-8 right-8 bg-primary text-white w-10 h-10 rounded-lg z-50 flex items-center justify-center" onClick={scrollToTop}>
                    <BsChevronDoubleUp />
                </button>
            )}
        </nav>
    );
};

export default Navbar;

import React from 'react';
import FamilyImg from '../assets/images/family.png';
import Navbar from './Navbar';
import Logo from '../assets/images/Logo.png'

const Hero = () => {
    const scrollToForm = () => {
        const formElement = document.getElementById('form');
        if (formElement) {
            formElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section id="hero" className='w-full min-h-screen px-10 sm:px-20 py-10 flex flex-col items-center justify-between' style={{ backgroundImage: `url(${FamilyImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Navbar />
            <div className='lg:w-2/3 p-6 md:px-20 sm:py-10 bg-white bg-opacity-50 rounded shadow'>
                <h1 className='text-4xl md:text-5xl font-semibold text-black tracking-tighter text-center mb-10'>
                    American could receive up to $5400 in health tax credits for grocery, rent, gas and more!
                </h1>
                <button onClick={scrollToForm} className='mx-auto px-8 pt-3 pb-1 flex justify-center gap-x-2 font-bold uppercase text-white text-center rounded-md shadow-lg border-2 border-secondary text-2xl bg-gradient-to-r from-primary to-secondary'>
                    qualify now
                    <img src={Logo} alt="logo" width={40}  />
                </button>
            </div>
            <div className='hidden md:block'></div>
        </section>
    );
};

export default Hero;
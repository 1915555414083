import React, { useState, useEffect } from 'react';

const Loading = ({ heading, text, classes, btnText, subText, status }) => {
    const [timeLeft, setTimeLeft] = useState(5 * 60);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <div className='container mx-auto max-w-5xl'>
            <div className={`flex flex-col shadow-lg bg-teal-50 items-center py-6 md:py-5 lg:py-5 rounded border-2 border-light px-3 ${status === "error" ? 'border-t-red-500' : status === "success" ? 'border-t-emerald-500' : 'border-t-yellow-300'} space-y-8`}>
                <div className='flex flex-col items-center space-y-4 lg:space-y-2'>
                    {heading && (
                        <h2 className={`font-semibold ${status === "success" ? 'text-3xl' : 'text-lg'} capitalize text-center lg:text-left`}>{heading}</h2>
                    )}
                    
                    <p className='text-sm text-center lg:text-left'>{text}</p>
                    
                    {subText && (
                        <p className='text-sm font-light text-center lg:text-left'>{subText}</p>
                    )}
                </div>

                {btnText && (
                    <a href={`facetime://${btnText}`} className="px-8 py-3 rounded-md capitalize text-lg text-white border-primary bg-primary mt-4 inline-block" style={{ width: 'auto' }}>{btnText}</a>
                )}
                {status === "success" && (
                    <div className='text-black font-semibold'>
                        Your spot is being held for {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Loading;
import React, { useState, useEffect } from 'react';
import Button from './Button';
import Loading from './Loading';

const INPUTS = [
    "Do you make under $50,000 per year?",
    "Are you on Medicaid/Medicare?"
];

const LoadingStates = [
    { heading: "Reviewing Your Answers", subtext: "" },
    { heading: "Searching Your State's Coverage", subtext: "" },
    { heading: "Confirming Eligibility", subtext: "" },
    { heading: "Confirming Eligibility", subtext: "" }
];

const Form = () => {
    const [formData, setFormData] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentLoadingStateIndex, setCurrentLoadingStateIndex] = useState(0);
    const [allLoadingStatesDisplayed, setAllLoadingStatesDisplayed] = useState(false);
    const [successfulReq, setSuccessfulReq] = useState(false);

    const handleSelection = (value) => {
        const updatedData = { ...formData, [INPUTS[currentQuestionIndex]]: value };
        setFormData(updatedData);
        if (currentQuestionIndex < INPUTS.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setCurrentQuestionIndex(INPUTS.length);
        }
    };

    useEffect(() => {
        if (currentQuestionIndex === INPUTS.length) {
            const salaryUnder50k = formData[INPUTS[0]];
            const onMedicare = formData[INPUTS[1]];
            const result = (salaryUnder50k && !onMedicare) || (!salaryUnder50k && !onMedicare);
            setSuccessfulReq(result);
        }
    }, [currentQuestionIndex, formData]);

    useEffect(() => {
        let timer;
        timer = setTimeout(() => {
            setCurrentLoadingStateIndex((prevIndex) => (prevIndex + 1) % LoadingStates.length);
        }, 2000);

        if (currentLoadingStateIndex === LoadingStates.length - 1) {
            setAllLoadingStatesDisplayed(true);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [currentLoadingStateIndex]);

    return (
        <section id='form' className='min-h-screen p-10 md:p-20'>
            <div className='md:w-2/3 mx-auto'>
                <h1 className='text-4xl text-center font-bold mb-6'>Easy Apply</h1>
                <p className='md:text-lg text-left md:text-justify mb-10'>
                    Americans who make under $50,000 per year are in for a surprise. Health Advanced Premium Credits are available starting this week to pay for expensive medical care everyday needs.
                    Thanks to a new government subsidiary program, residents could get $0 Free Health Insurance and Health Assistance Allowance for everyday expenses.
                    This means more to spend on things like groceries, bills, rent, gas, and other important day-to-day essentials.
                    It's easy and completely free to check eligibility. To get started, take the short quiz below and speak with an expert to claim your benefits.
                </p>
                <div className='flex flex-col items-center bg-gray-100 shadow rounded px-6 py-10 md:p-20'>
                    {(currentQuestionIndex < INPUTS.length) &&
                        <div className='flex flex-col space-y-8 md:w-1/2'>
                            <h1 className='text-3xl font-bold text-center'>{INPUTS[currentQuestionIndex]}</h1>
                            <div className="flex flex-col space-y-4">
                                <Button onClick={() => handleSelection(true)} className="w-full" text="yes" />
                                <Button onClick={() => handleSelection(false)} className="w-full" text="no" />
                            </div>
                        </div>
                    }
                    {(currentQuestionIndex === INPUTS.length && !allLoadingStatesDisplayed) && (
                        <Loading status='loading' heading={LoadingStates[currentLoadingStateIndex].heading} text={LoadingStates[currentLoadingStateIndex].subtext} />
                    )}
                    {((currentQuestionIndex === INPUTS.length && allLoadingStatesDisplayed && !successfulReq) && (
                        <Loading status='error' heading="Sorry, we can't help you." text='The questions you answered have helped us determine that we are not able to help you at this time.' />
                    ))}
                    {((currentQuestionIndex === INPUTS.length && allLoadingStatesDisplayed && successfulReq) && (
                        <Loading status='success' heading="Congratulations!" text='You Pre-Qualify for $0/Month Health Insurance and Up To $6400/Month Health Credits' subText='Your spot is being held. To get started click the button below to speak to an expert.' btnText="855-960-1712" />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Form;
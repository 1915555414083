import Hero from '../src/components/Hero';
import Form from '../src/components/Form';
import Footer from '../src/components/Footer';

function App() {
    return (
        <div className="App font-josefin">
            <Hero />
            <Form />
            <Footer />
        </div>
    );
}

export default App;
